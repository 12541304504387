<template>
  <div class="px-4 py-2" style="user-select:none">
    <div class="p-2" :style="{ paddingBottom: 60 + 'px' }">
      <div class="pt-1 bg-white rounded-sm ">
        <div class="flex items-center justify-between">
          <div class="pl-2 m-2 border-l-4 border-primary text-bold">
            选择商户
          </div>
          <div
            class="flex items-center justify-center font-bold rounded text-primary"
            style="background:#F6F6F6;height: 30px;padding: 0px 10px;"
          >
            {{
              selectClient.member_id
                ? "已选择商户：" + selectClient.nickname
                : "未选择商户"
            }}
          </div>
        </div>
        <div class="px-2 py-1 my-3 item">
          <a-table
            :data-source="dataSource"
            :columns="columns"
            :scroll="{ x: 1000 }"
            :pagination="pagination"
            @change="change"
          >
            <template slot="operation" slot-scope="text, record">
              <div class="flex">
                <div class="pr-2 editable-row-operations">
                  <a @click="() => selectClientFunc(record)">选择</a>
                </div>
              </div>
            </template>
          </a-table>
        </div>
        <div class="pl-2 m-2 border-l-4 border-primary text-bold">
          选择采样机型
        </div>
              <div v-for="(val,index) in this.robotClass"
                class="flex justify-between px-2 py-1 my-3 item align-center"
                :class="selectTypeOne == index ? 'millType' : ''"
                @click="changeMillType(index)"
              >
                <div class="flex items-center">
                  <img
                    src="../../static/subscribe/clothes2.png"
                    alt=""
                    style="width: 40px;height: auto;"
                  />
                  <div
                    class="pl-2 text-base text-bold"
                    :class="millType == 2 ? 'text-whtie' : ''"
                  >
                    {{val.name}}
                  </div>
                </div>
                <!-- <div class="flex align-center">
                  <img
                    src="../../static/subscribe/clothes2.png"
                    alt=""
                    style="width: 40px;height: auto;"
                  />
                  <div class="pl-2 text-base text-bold">男上模</div>
                </div> -->
                <div class="iconbox">
                  <span
                    class="span-white etcfont etc-gouxuan"
                    style="font-size: 20px;"
                    v-if="selectTypeOne == index"
                  ></span>
                  <span
                    class="span-gray-200 etcfont etc-bugouxuan"
                    v-else
                    style="font-size: 20px;"
                  ></span>
                </div>
              </div>


      </div>
      <div class="p-2 mt-2 bg-white rounded-sm">
        <div
          class="flex justify-between pl-2 mb-2 border-l-4 border-primary text-bold"
        >
          <div>选择采样机器人</div>
          <!-- <div class="text-primary">B00101</div> -->
        </div>
        <div class="flex flex-wrap">
          <div
            :class="indexRobot == index ? 'type_active' : ''"
            @click="selectRobot(item,index)"
            v-for="(item, index) in typeList"
            :key="item.id"
            class="flex justify-center p-2 mx-1 my-1 rounded-sm list type_list align-center"
          >
            {{ item.name }}
          </div>
        </div>
      </div>



      <div class="p-2 mt-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          选择采样规格
        </div>
        <div class="flex justify-between mt-2 size">
          <div
            class="flex items-center justify-center rounded cursor-pointer"
            :class="size == item ? 'type_active' : ''"
            @click="size = item"
            v-for="item in sizeType"
            :key="item"
            style="width:80px;height:40px"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <!-- 填写数据范围 -->
      <div class="p-2 mt-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
            填写数据范围 <!--<div style="display:inline;float:right;margin-right:100px;" class="text-sm min text-gray">机器变型返回</div>  -->
        </div>
        <div class="px-1 my-2">
          <div class="flex items-center py-2 header">
            <div class="text-sm title text-gray">部位</div>
             <div class="text-sm section text-gray" style="flex:1;margin-right:60px;">机器变型返回</div> 
            <!-- <div class="text-sm min text-gray">最小值</div>
            <div class="text-sm max text-gray">最大值</div> -->
           <!-- <div style="display:inline;float:right;margin-right:100px;" class="text-sm min text-gray">机器变型返回</div>  -->
           
          </div>

          <div
            class="flex items-center py-2 size_item"
            v-for="(item,key, index) in robotConfigC"
            :key="item.sort"
          >
            <div class="text-base title">{{ item.name }}</div>
            <div id="asliderid"
              class="flex items-center text-sm section text_box"
              style="flex:1"
            >
              <a-slider  :tooltipVisible="true"
                range 
                :min = item.min :max = item.max
                :default-value="[item.min, item.max]"
                style="width: 100%;"
                @change="rangeChange($event, key,index)"  :getTooltipPopupContainer="(triggerNode) => triggerNode.parentNode"
              />
            </div>
            <div v-once class="flex text-sm text-right min text_box">
              <!-- <input 
                type="number"
                class="box min_box text-gray"
                v-model="item.min"
                placeholder="cm"
              /> -->
              ({{item.min}}))
            </div>
            <div v-once class="flex text-sm text-right max text_box">
              <!-- <input
                type="number"
                class="box min_box text-gray"
                v-model="item.max"
                placeholder="cm"
              /> -->
              ({{item.max}})
            </div>
          </div>
        </div>
      </div>

      <div class="p-2 mt-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          选择预约日期
        </div>
        <div class="flex justify-between w-full mt-2">
          <my-calendar
            ref="maCalendar"
            :millType="millType"
            :maxDate="maxDate"
            :mode="mode"
            :robot="type"
            @selective="selectiveMethod"
            @change="change"
            active-bg-color="#294DEA"
          ></my-calendar>
        </div>
      </div>

      <div class="p-2 mt-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          选择钟点
        </div>
        <div>
          <div
            class="flex flex-wrap mt-2 scroll_box"
            v-if="timeNode.length > 0"
          >
            <div
              class="flex items-center justify-center m-1 cursor-pointer date_list"
              v-for="(item, index) in timeNode"
              :key="index"
              :class="{
                date_selectitem: item.status == '',
                date_list_active: item.select,
              }"
              @click="selectTime(index)"
            >
              <div class="time_box">
                <div class="text-center time">
                  {{ item.start_time }}~{{ item.end_time }}
                </div>
                <div class="text-center info" v-if="item.status == 1">已约</div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-between w-full mt-2 scroll_box"
            v-else
          >
            <div class="w-full text-center">暂无可预约的时间</div>
          </div>
        </div>
      </div>



      <div class="p-2 my-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          自定义商品名称
        </div>
        <div class="p-2 mt-2 rounded-sm textarea_box">
          <input
            v-model="name"
            type="textarea"
            placeholder="请输入商品名称"
            style="background: none;outline: none;border: none;width:100%"
          />
        </div>
      </div>
      <div class="p-2 my-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          商品自编码
        </div>
        <div class="p-2 mt-2 rounded-sm textarea_box">
          <input
            v-model="self_code"
            type="textarea"
            placeholder="请输入商品自编码"
            style="background: none;outline: none;border: none;width:100%"
          />
        </div>
      </div>
      <!-- <div class="p-2 my-2 bg-white rounded-sm">
        <div class="pl-2 border-l-4 border-primary text-bold">
          优惠券码
        </div>
        <div class="p-2 mt-2 rounded-sm textarea_box">
          <input
            v-model="code"
            type="textarea"
            placeholder="请输入优惠券码"
            style="background: none;outline: none;border: none;width:100%"
          />
        </div>
      </div> -->

      <div class="p-2 mt-2 bg-white rounded-sm">
        <div
          class="flex justify-between pl-2 mb-2 border-l-4 border-primary text-bold"
        >
          <div style="width:100%;">选择采样标签 <span style="float:right;" @click = 'tags_showf()' v-if="tags_show == false">显示</span><span style="float:right;" @click = 'tags_showf()' v-if="tags_show == true">隐藏</span></div>
          <!-- <div class="text-primary">B00101</div> -->
        </div>
        <!-- <div class="flex items-center py-2">
					<div class="" @click="selectTags('1')">
						{{select['1'].name ? select['1'].name :"点击选择"}}
					</div>
					<div class="mx-2" @click="selectTags('2')" v-if="select['2'].show">
						{{select['2'].name ? select['2'].name :"点击选择"}}
					</div>
					<div class="" @click="selectTags('3')" v-if="select['3'].show">
						{{select['3'].name ? select['3'].name :"点击选择"}}
					</div>
				</div> -->
        <div v-if="tags_show" class="py-2">
          <div
            class="flex cate_item"
            v-for="(item, index) in tagList"
            :key="index"
          >
            <div class="flex justify-center" style="width: 33.33%;">
              <div
                class="flex items-center justify-center p-2 my-1 rounded-sm list type_list type_active"
                style="width: 95%;"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="" style="width: 33.33%;">
              <div
                class="flex justify-center"
                v-for="(second, secondIndex) in item.children"
                :key="secondIndex"
              >
                <div
                  class="flex items-center justify-center p-2 my-1 rounded-sm cursor-pointer list type_list"
                  style="width: 95%;"
                  :class="
                    secondIndex == select[index].second ? 'type_active' : ''
                  "
                  @click="selectFunc(index, secondIndex)"
                >
                  {{ second.name }}
                </div>
              </div>
            </div>
            <div class="" style="width: 33.33%;">
              <div
                class="flex justify-center"
                v-for="(third, thirdIndex) in item.children[
                  select[index].second
                ].children"
                :key="thirdIndex"
              >
                <div
                  class="flex items-center justify-center px-2 py-1 my-1 rounded-sm cursor-pointer list type_list"
                  style="width: 90%;"
                  :class="
                    thirdIndex == select[index].third ? 'type_active' : ''
                  "
                  @click="selectFunc(index, -1, thirdIndex)"
                >
                  {{ third.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a-upload-dragger style="width:40%;float:left;"
        name="file"
        :multiple="false"
        :action="action"
        :limit="1"
        @change="handleChange"            
        :headers="headers"
        ref="upload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">点击或者将文件拖入此处上传</p>
        <span data-v-11a6115c="" style="font-size: 12px; color: #294DEA;">该图片用于核对是否与送样服装一致</span>
      </a-upload-dragger>
      
      <a-upload-dragger style="width:40%;float:right;"
        name="file"
        :multiple="false"
        :action="action"
        :limit="1"
        @change="handleChange1"              
        :headers="headers"
        ref="upload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">点击或者将文件拖入此处上传</p>
        <span data-v-11a6115c="" style="font-size: 12px; color: #294DEA;">该图片用于在商品订单列表中封面的展示</span>
      </a-upload-dragger>
 
      <div style="clear:both" class="flex justify-center">
        <a-button
          type="primary"
          size="large"
          style="width:200px"
          @click="addPlan"
        >
          添加
        </a-button>
      </div>
      <a-modal v-model:visible="visible" title="" @ok="handleOk">
        <table style="width:100%">
		 <tr >
			<!-- <td>

				<u-checkbox-group style="margin:2px 0px -2px 10px;display:block" v-if="fresh">
					<u-checkbox ref="moniclick" @change="checkboxChangeq()" value="index" v-model="radio_q" ></u-checkbox>
				</u-checkbox-group>
			</td> -->
      <td style="width:2%;">
      <a-checkbox @change="changeq" v-model:checked="radio_q"></a-checkbox></td>
			<td style="width:15%;text-align: center;" v-if="key!='arm'" v-for="(item,key) in shape_list[0]" >{{size_config[key]}}</td>
			
		</tr> 
		<tr v-for="(item,index) in shape_list">
			<td style="width:2%;">

				<!-- <u-checkbox-group style="margin:2px 0px -2px 10px;display:block" v-if="fresh">
					<u-checkbox @change="checkboxChange()" value="index" v-model="radio_arr[index].if" ></u-checkbox>
				</u-checkbox-group> -->
        <a-checkbox @change="changec" v-model:checked="radio_d[index]"></a-checkbox>
			</td>
			<td style="width:15%;text-align: center;" v-if="key!='arm'" v-for="(end,key,index) in item">{{end}}</td>

	
		</tr>

	</table>
  <div>总价：{{totle}}</div>
  <div>优惠券吗：<a-input v-model="free" /></div>
    </a-modal>
    </div>
  </div>
</template>
<script>
import { getModel,getTypeModel, getTimes,collectAddget, collectAdd ,getShapeList} from "@/api/collect";
import { tagList } from "@/api/tags";
import { size_config } from "@/api/size_config";
import { MemberList } from "@/api/client";
import myCalendar from "@/components/calendar/calendar";
import { BASE_URL } from "@/utils/common";
export default {
  components: {
    myCalendar,
  },
  data() {
    return {
      free:'',
      totle:0,
      radio_q:false,
      radio_d:[],
      size_config:{
    "arm":  '上臂围',
 
      "bust":  '胸围',
 
      "height":  '身长' ,
 
      "length" : '上身长',
 
      "neck_circumference":  '颈围',
 
      "shoulder":  '肩宽',
   
      "shoulder_width":  '肩宽',
 
      "shoulder_circumference":  '肩围',
 
     "upper_arm_circumference":  '臂围',
 
     "under_bust":  '胸底围',
 
      "waist": '腰围',
 
      "high_hip":  '跨围',
 
      "low_hip":  '臀围',
 
      "hipline":  '臀围',
 
      "thigh_circumference":  '大腿围',
 
      "thigh_length":  '大腿长',
 
      "calf_circumference":  '小腿围',
 
      "calf_length":  '小腿长',
      'hipheight':'臀高',
      'lower':'下身长'

 
 },
      unit_price:'1.00',
      shape_list:[],
      tags_show:false,
      selectTypeOne:0,
      action:'',
      visible:false,
      sample_pic:'',
      main_pic:'',
      getModelList: [],
      getTimesList: [],
      robotClass:'',
      mode: "date",
      millType: 1,
      millName: ["男上模", "女上模", "通用下模"],
      typeList: [],
      type: 0,
      date: 0,
      indexRobot:0,
      min:0,
      max:0,
      timeNode: [],
      name: "",
      self_code: "",
      value: "",
      robotConfig:'',
      robotConfigC:'',
      size: "XS",
      sizeType: ["XS", "S", "M", "L", "XL"],
      dataObject: [
        {
          title: "身高",
          range: [5, 40],
          min: "",
          max: "",
          type: "length",
        },
        {
          title: "肩宽",
          range: [5, 40],
          min: "",
          max: "",
          type: "shoulder",
        },
        {
          title: "上臂围",
          range: [5, 40],
          min: "",
          max: "",
          type: "arm",
        },
        {
          title: "胸围",
          range: [5, 40],
          min: "",
          max: "",
          type: "bust",
        },
        {
          title: "腰围",
          range: [5, 40],
          min: "",
          max: "",
          type: "waist",
        },
        {
          title: "臀围",
          range: [5, 40],
          min: "",
          max: "",
          type: "hipline",
        },
        {
          title: "臀高",
          range: [5, 40],
          min: "",
          max: "",
          type: "hipheight",
        },
        {
          title: "下身长",
          range: [5, 40],
          min: "",
          max: "",
          type: "lower",
        },
        {
          title: "大腿围",
          range: [5, 40],
          min: "",
          max: "",
          type: "thigh",
        },
        {
          title: "大腿长",
          range: [5, 40],
          min: "",
          max: "",
          type: "thigh_length",
        },
        {
          title: "小腿围",
          range: [5, 40],
          min: "",
          max: "",
          type: "calf",
        },
      ],
      headers:{},
      show: false,
      list: [[], []],
      showSelect: false,
      selectType: "",

      maxDate: "2050-12-31",

      // 判断当前日期预约是否已满（由myCalendar组件返回）ture 为已满
      selective: false,
      selectkey: "", //选择的时间节点

      tagList: [],
      first: [],
      second: [],
      third: [],
      showTag: false,
      select: {},
      selectCurrent: 1,
      forEachIndex: 0,
      code: "",
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 10,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      columns: [
        {
          title: "商户名称",
          dataIndex: "nickname",
          key: "nickname",
          scopedSlots: { customRender: "nickname" },
          width: 150,
        },
        {
          title: "商户编号",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          fixed: "right",
          width: 140,
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataSource: [],
      selectClient: {},
    };
  },
  created() {
    console.log(size_config)
    const ACCESS_TOKEN = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
    this.headers["AdminToken"] = ACCESS_TOKEN.token;
    this.headers["AdminUserId"] = ACCESS_TOKEN.userId;
    this.action = BASE_URL + "/admin/file/add";
    this.aquireClass();
    this.aquireType();
    // this.queryTime();
    this.aquireTags();
    this.auquireClients();
  },
  methods: {
    // 请求客户列表
    changeq(){
      if(this.radio_q == true){
        this.totle =    Number(this.unit_price)*this.shape_list.length
        for(let i =0;i<this.shape_list.length;i++){
          this.radio_d[i]=true
        }
      }else{
        this.totle = 0
        for(let i =0;i<this.shape_list.length;i++){
          this.radio_d[i]=false
        } 
      }
   
    },
    changec(){
      let num =0
      for(let i =0;i<this.shape_list.length;i++){
          if(this.radio_d[i]==true){
            num++
          }
        } 
        this.totle =    Number(this.unit_price)*num
    },
    tags_showf(){
    this.tags_show = !this.tags_show
    },
    handleChange(info) {
      this.main_pic = info.file.response.data.file_path
      console.log(info)
      console.log('ok')
    },
    handleChange1(info) {
      this.sample_pic = info.file.response.data.file_path
      console.log(info)
      console.log('ok')
    },
    auquireClients() {
      MemberList({
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
      })
        .then((r) => {
          console.log(r);
          let list = r.data.list;
          list.map((item, index) => {
            item.key = index;
            item.tags = ["查看详情"];
          });
          this.dataSource = [];
          this.dataSource.push(...list);
          this.pagination.total = r.data.count;
          console.log(this.pagination.total);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.auquireClients();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      // console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.auquireClients();
    },
    // 选择商户
    selectClientFunc(row) {
      // console.log(row);
      this.selectClient = Object.assign({}, row);
    },
    rangeChange(e, key,index) {
      //   console.log(e, index);
      //console.log(e)
      //console.log(index)
      //console.log(key)
      console.log(this.robotConfig[key].min)
      this.robotConfig[key].min = e[0]
      this.robotConfig[key].max = e[1]
      console.log(e[0])
      this.min = e[0]
      this.max = e[1]
      console.log(this.max)
      this.zhixing(e,key,index)
    },
    zhixing(e,key,index){
      this.robotConfig[key].min = e[0];
      this.robotConfig[key].max = e[1];
    },
    // 选择
    selectFunc(index, secondIndex, thirdIndex) {
      secondIndex != -1 ? (this.select[index].second = secondIndex) : "";
      thirdIndex || thirdIndex == 0
        ? (this.select[index].third = thirdIndex)
        : "";
    },
    change(e) {
      this.date = e.result;
      this.queryTime();
    },
    // 获取标签列表
    aquireTags() {
      tagList()
        .then((res) => {
          if (res.code == 200) {
            res.data.list.forEach((item, index) => {
              this.$set(this.select, index, {
                second: 0,
                third: 0,
              });
            });
            this.tagList = [];
            this.tagList.push(...res.data.list);
            console.log(this.select);
          }
        })
        .catch((error) => {});
    },
    aquireClass(){
      getTypeModel({}).then((r)=>{
        console.log(r)
        if (r.code == 200) {
          this.robotClass = r.data
          this.millType = this.robotClass[0].id;
          console.log(this.millType)
        }
      })
    },
    
    // 获取采样机器人
    aquireType() {
      console.log(this.millType)
      getModel({ model: this.millType }).then((r) => {
        if (r.code == 200) {
          console.log(r);
          this.typeList = [];
          this.typeList.push(...r.data);
          console.log(this.typeList)
          console.log('abc')
          this.type = this.typeList[0].id;

          this.robotConfig = JSON.parse(this.typeList[this.indexRobot].cfg);
          this.robotConfigC = JSON.parse(this.typeList[this.indexRobot].cfg);
          for(let key in this.robotConfig){
            this.robotConfig[key].min =parseInt(this.robotConfig[key].min)
            this.robotConfig[key].max =parseInt(this.robotConfig[key].max)
          }
          for(let key in this.robotConfigC){
            this.robotConfigC[key].min =parseInt(this.robotConfigC[key].min)
            this.robotConfigC[key].max =parseInt(this.robotConfigC[key].max)
          }
          console.log(this.robotConfig)
          this.queryTime();
        }
      });
    },
    // 获取钟点
    queryTime() {
     // this.robotConfig = this.typeList[0].cfg
     // console.log(this.robotConfig)
      if (this.millType < 0) {
        return;
      }
      getTimes({
        model: this.millType,
        robot_id: this.type,
        date: this.date,
      }).then((r) => {
        if (r.code == 200) {
          this.timeNode = [];
          this.timeNode.push(...r.data);
          this.timeNode.forEach((item) => {
            this.$set(item, "select", false);
          });
          // console.log(this.timeNode);
        }
      });
    },
    // 更改采样机型
    changeMillType(type) {
      this.selectTypeOne = type
      console.log(this.selectTypeOne)
      this.millType = this.robotClass[type].id;
      console.log(this.millType)
      this.aquireType();
      

      this.$refs.maCalendar.queryDate(this.date, this.millType);
    },
    selectRobot(item,index){
      this.type = item.id
      this.indexRobot = index
      this.aquireType()
    },
    // 生成list数据
    gernerateList() {
      let minList = [];
      let maxList = [];
      for (let i = 10; i <= 200; i += 2) {
        this.list[0].push({
          value: i,
          label: i + "",
        });
        this.list[1].push({
          value: i,
          label: i + "",
        });
      }
    },
    selectiveMethod(flag) {
      // console.log(flag)
      this.selective = flag;
    },
    selectTime(key) {
      this.selectkey != "" || this.selectkey === 0
        ? (this.timeNode[this.selectkey].select = false)
        : "";
      this.selectkey = key;
      this.timeNode[key].select = true;
    },
    addPlan() {

      if (!this.judge()) {
        return;
      }

      let tags = [];
      for (let key in this.select) {
        // tags.push(this.tagList[key].id);
        if (
          this.tagList[key].children &&
          this.tagList[key].children.length > 0
        ) {
          let secondChildren = this.tagList[key].children[
            this.select[key].second
          ];

          if (secondChildren.children && secondChildren.children.length > 0) {
            tags.push(secondChildren.children[this.select[key].third].id);
          } else {
            tags.push(secondChildren.id);
          }
        }
      }
      let data = {
        model: this.millType, // 采样机型：1-男上模，2-女上模，3-通用下模
        name: this.millName[this.millType - 1], // 采样机型名称
        robot_id: this.type,
        model_id: this.type, // 采样类别ID
        time_id: this.timeNode[this.selectkey].id, // 预约时间ID
        size: this.size, // 规格
        good_name: this.name, // 自定义商品名称
        self_code: this.self_code,
        tags,
        sample_pic:this.sample_pic,
      main_pic:this.main_pic,
        code: this.code,
        merchant_id:this.selectClient.merchant_id,
        member_id: this.selectClient.member_id,
      };

      for(let key in this.robotConfig){
        let range =""+this.robotConfig[key].min+"~"+this.robotConfig[key].max+""
        data[key] = [range,this.robotConfig[key].min,this.robotConfig[key].max];
      }
      console.log(data)
      console.log('dddddddd')

      getShapeList(data).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.visible = true
          this.shape_list = res.data.shape_list
          this.unit_price = res.data.unit_price

        }else{
          this.$message.error(res.msg);
        }
      })
      // collectAdd(data).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("订单添加成功");
      //     this.queryTime();
      //     this.code = "";
      //     this.self_code = "";
      //     this.name = "";
      //     this.dataObject.forEach((item) => {
      //       item.min = "";
      //       item.max = "";
      //       item.range = [0, 40];
      //     });
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // });
    },
    handleOk(){
      let slist =[]
      for(let i =0;i<this.shape_list.length;i++){
          if(this.radio_d[i]==true){
            slist.push(this.shape_list[i])
          }
        } 

        let tags = [];
      for (let key in this.select) {
        // tags.push(this.tagList[key].id);
        if (
          this.tagList[key].children &&
          this.tagList[key].children.length > 0
        ) {
          let secondChildren = this.tagList[key].children[
            this.select[key].second
          ];

          if (secondChildren.children && secondChildren.children.length > 0) {
            tags.push(secondChildren.children[this.select[key].third].id);
          } else {
            tags.push(secondChildren.id);
          }
        }
      }
      let data = {
        model: this.millType, // 采样机型：1-男上模，2-女上模，3-通用下模
        name: this.millName[this.millType - 1], // 采样机型名称
        robot_id: this.type,
        model_id: this.type, // 采样类别ID
        time_id: this.timeNode[this.selectkey].id, // 预约时间ID
        size: this.size, // 规格
        good_name: this.name, // 自定义商品名称
        self_code: this.self_code,
        tags,
        sample_pic:this.sample_pic,
      main_pic:this.main_pic,
        // code: this.code,
        merchant_id:this.selectClient.merchant_id,
        member_id: this.selectClient.member_id,
        shape_list: slist,
        coupon:this.free
      };

      for(let key in this.robotConfig){
        let range =""+this.robotConfig[key].min+"~"+this.robotConfig[key].max+""
        data[key] = [range,this.robotConfig[key].min,this.robotConfig[key].max];
      }
      console.log(data)
      console.log('dddddddd')


            collectAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("订单添加成功");
          this.visible =false
          // this.queryTime();
          // this.code = "";
          // this.self_code = "";
          // this.name = "";
          // this.dataObject.forEach((item) => {
          //   item.min = "";
          //   item.max = "";
          //   item.range = [0, 40];
          // });
        } else {
          this.$message.error(res.msg);
        }
      });
    
    },
    judge() {
      // if (this.selective) {
      // 	this.$message.error({
      // 		title: '您所选择的预约日期已经约满，请重新选择日期',
      // 		type: 'warning',
      // 	})
      // 	return
      // }
      if (!this.selectClient.member_id || this.selectClient.member_id == "") {
        this.$message.error("请选择商户");
        return false;
      }
      if (this.millType < 0) {
        this.$message.error("请选择采样机型");
        return false;
      }
      if (this.selective == "undefied") {
        this.$message.error("该日期内暂未提供预约计划，请重新选择日期");
        return false;
      }
      if (this.timeNode.length < 1) {
        this.$message.error("该日期内暂无可预约时间，请重新选择日期");
        return false;
      }
      if (this.name == "") {
        this.$message.error("请输入商品名称");
        return false;
      }
      if (this.self_code == "") {
        this.$message.error("请输入商品自编码");
        return false;
      }
      if (this.code == "") {
        this.$message.error("请输入优惠券码");
        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.subscribe {
  width: 100vw;
  overflow: hidden;
}

.subscribe .cate_item:not(:last-child) {
  border-bottom: 4px solid #eef3f3;
  padding-bottom: 20px;
}

.subscribe .cate_item:not(:first-child) {
  padding-top: 20px;
}

.my_tabbar {
  @apply flex flex-row items-center justify-center p-2 bg-primary;
}

.circle {
  bottom: 0;
  left: 50%;
  height: 800px;
  width: 800px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.1;
  transform: translateX(-50%);
}

.item {
  image {
    width: 96px;
    height: auto;
  }

  .iconbox {
    .etcfont {
      font-size: 46px;
    }
  }
}

.list {
  background: #f7f7f7;
}

.size {
  view {
    background: #f7f7f7;
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.date_list {
  box-sizing: border-box;
  height: 60px;
  width: 15.5%;
  background: #f7f7f7;
  margin-top: 20px;
  border-radius: 10px;
  color: #807e81;
  padding: 6px 0px;
  box-sizing: border-box;
  min-width: 150px;
  view {
    font-size: 24px;
  }

  .info {
    color: #ff5c2b;
  }
}

.date_list_active {
  background-color: white;
  border: 2px solid #294DEA;
  color: #294DEA;

  view {
    color: #294DEA;
  }
}

.date_select {
  opacity: 0.5;
}

.scroll_box {
  //   height: 400px;
}

.title {
  width: 100px;
  text-align: left;
}

.section {
  width: 250px;
  text-align: right;
}

.section_box {
  width: 230px;
}

.min {
  width: 150px;
  text-align: right;
}

.min_box {
  width: 140px;
}

.max {
  width: 150px;
  text-align: right;
}

.max_box {
  width: 140px;
}

.box {
  background: #f7f7f7;
  height: 40px;
  line-height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.text_box {
  justify-content: flex-end;
}

.select_img {
  image {
    width: 20px;
    height: auto;
  }
}

.textarea_box {
  background-color: #f7f7f7;
}

.btnbox {
  height: 120px;
  box-shadow: 0px 1px 0px 0px #dddddd;
  background: white;
  width: 100vw;
}

.btn {
  width: 336px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 10px;
}

.left_btn {
  background-color: white;
  border: 2px solid #294DEA;
}

.right_btn {
  color: white;
  background-color: #294DEA;
}

.type_list {
  box-sizing: border-box;
  width: 152rpx;
  height: 40px;
}

.type_active {
  border: 2px solid #294DEA;
  color: #294DEA;
}

.millType {
  background-color: #294DEA;
  border-radius: 10px;

  view {
    color: #ffffff;
  }
}
</style>
